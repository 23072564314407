import React, { useState, useEffect } from "react";
import { orderdetails } from "../utils/api";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Delivery from "../components/delivery";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Orderdetail = () => {
  const [order, setOrder] = useState({});
  const { id } = useParams();

  const getOrder = async () => {
    const data = {
      orderid: id,
    };
    const details = await orderdetails(data);
    setOrder(details.ordermaster[0]);
    console.log(details.ordermaster[0], "check");
  };

  useEffect(() => {
    getOrder();
  }, [id]);

  // Function to calculate the subtotal
  const calculateSubtotal = () => {
    if (!order.orderdetail) return 0;

    return order.orderdetail.reduce((total, item) => {
      // Add base amount of the item
      let itemTotal = parseFloat(item.amount) || 0;

      // Add amounts from each top-up detail
      if (item.ordertopupdetail) {
        itemTotal += item.ordertopupdetail.reduce((topupTotal, topup) => {
          return topupTotal + (parseFloat(topup.amount) || 0);
        }, 0);
      }

      return total + itemTotal;
    }, 0);
  };

  const subtotal = calculateSubtotal();
  // Ensure subtotal is a number
  const validSubtotal = typeof subtotal === "number" ? subtotal : 0;
  const deliveryCharge = parseFloat(order.deliverycharges) || 0;
  const total = validSubtotal + deliveryCharge;

  return (
    <>
      {/* Header section start */}
      <Navbar />
      {/* Header Section end */}
      <section className="section-t-space mytabb overflow-hidden pt-120">
        {/* <div className="container text-center">
      <div className="tab">
        <div>
          <Link className="tablinks active">
            <p>
              <i className="fa fa-motorcycle" /> Delivery
            </p>{" "}
            <p className="smtext">35 - 50 Min</p>
          </Link>
          <Link className="tablinks">
            <p>
              <i className="fa fa-shopping-bag" aria-hidden="true" /> Collection{" "}
            </p>{" "}
            <p className="smtext">15 - 25 Min</p>
          </Link>
        </div>
      </div>
    </div> */}
        <Delivery />
      </section>
      <section className="page-head-section">
        <div className="container page-heading">
          <h2 className="h3 mb-3 text-white text-center">Order details</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
              <li className="breadcrumb-item">
                <Link href="index.html">
                  <i className="ri-home-line" />
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Order details
              </li>
            </ol>
          </nav>
        </div>
      </section>
      {/*  account section starts */}
      <section className="account-section section-b-space pt-0">
        <div className="container">
          <div className="layout-sec">
            <div className="row justify-content-center g-lg-4 g-4">
              <div className="order-details-content">
                <div className="layout-sec">
                  <div className="order-summery-section sticky-top">
                    <div className="checkout-detail">
                      <div className="cart-address-box">
                        <div className="add-img">
                          <img
                            className="img-fluid img sm-size"
                            src="/assets/images/svg/location.svg"
                            alt="rp1"
                          />
                        </div>
                        <div className="add-content">
                          <div className="d-flex align-items-center justify-content-between">
                            <h5 className="dark-text deliver-place">
                              Deliver to : Home
                              <i className="ri-check-line" />
                            </h5>
                          </div>
                          <h6 className="address mt-sm-2 mt-1 content-color">
                            {order.deliveryaddress}
                          </h6>
                        </div>
                      </div>
                      <ul>
                        {order.orderdetail &&
                          order.orderdetail.map((item) => (
                            <li key={item.orderdetailid}>
                              <div className="horizontal-product-box">
                                <div className="product-content">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5>{item.food}</h5>
                                    <h6 className="product-price">
                                    £{item.amount}
                                    </h6>
                                  </div>
                                  {item.ordertopupdetail.length > 0 && (
                                    <div className="topup-details ">
                                      {item.ordertopupdetail.map((topup) => (
                                        <div key={topup.ordertopupdetailid}>
                                          <div className="d-flex align-items-center justify-content-between">
                                            {topup.extopupname && (
                                              <h6 className="ingredients-text">
                                                {topup.extopupname}
                                              </h6>
                                            )}
                                            <h6>£{topup.amount}</h6>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                      <h5 className="bill-details-title fw-semibold dark-text">
                        Bill Details
                      </h5>
                      <div className="sub-total">
                        <h6 className="content-color fw-normal">Sub Total</h6>
                        <h6 className="fw-semibold">£{subtotal.toFixed(2)}</h6>
                      </div>
                      <div className="sub-total">
                        <h6 className="content-color fw-normal">
                          Delivery Charge (2 Miles)
                        </h6>
                        <h6 className="fw-semibold success-color">
                        £{deliveryCharge.toFixed(2)}
                        </h6>
                      </div>
                      {/* <div className="sub-total">
                        <h6 className="content-color fw-normal">Discount (10%)</h6>
                        <h6 className="fw-semibold">-£{discount.toFixed(2)}</h6>
                      </div> */}
                      <div className="grand-total">
                        <h6 className="fw-semibold dark-text">Total</h6>
                        <h6 className="fw-semibold amount">
                        £{total.toFixed(2)}
                        </h6>
                      </div>
                      <img
                        className="dots-design"
                        src="/assets/images/svg/dots-design.svg"
                        alt="dots"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="account-btn d-flex justify-content-center gap-2">
                <Link href="index.html" className="btn theme-btn mt-0">
                  Back to home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* account section end */}
      {/* footer section starts */}
      <Footer />
      {/* footer section end */}
      {/* mobile fix menu start */}
      <div className="mobile-menu d-md-none d-block mobile-cart">
        <ul>
          <li className="active">
            <Link href="index.html" className="menu-box">
              <i className="ri-home-4-line" />
              <span>Home</span>
            </Link>
          </li>
          <li className="mobile-category">
            <Link href="" className="menu-box">
              <i className="fa fa-cutlery mb-2 pt-1" />
              <span>Dining</span>
            </Link>
          </li>
          <li>
            <Link href="history.html" className="menu-box">
              <i className="ri-apps-line" />
              <span>History</span>
            </Link>
          </li>
          <li>
            <Link href="setting.html" className="menu-box">
              <i className="fa fa-user mb-2 pt-1" />
              <span>Setting</span>
            </Link>
          </li>
        </ul>
      </div>
      {/* mobile fix menu end */}
      {/* location offcanvas start */}
      <div
        className="modal fade location-modal"
        id="location"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">
                <h5 className="fw-semibold">Select a Location</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="search-section">
                <form className="form_search" role="form">
                  <input
                    type="search"
                    placeholder="Search Location"
                    className="nav-search nav-search-field"
                  />
                </form>
              </div>
              <Link href="" className="current-location">
                <div className="current-address">
                  <i className="ri-focus-3-line focus" />
                  <div>
                    <h5>Use current-location</h5>
                    <h6>Wellington St., Ottawa, Ontario, Canada</h6>
                  </div>
                </div>
                <i className="ri-arrow-right-s-line arrow" />
              </Link>
              <h5 className="mt-sm-3 mt-2 fw-medium recent-title dark-text">
                Recent Location
              </h5>
              <Link href="" className="recent-location">
                <div className="recant-address">
                  <i className="ri-map-pin-line theme-color" />
                  <div>
                    <h5>Bayshore</h5>
                    <h6>kingston St., Ottawa, Ontario, Canada</h6>
                  </div>
                </div>
              </Link>
            </div>
            <div className="modal-footer">
              <Link href="#" className="btn gray-btn" data-bs-dismiss="modal">
                Close
              </Link>
              <Link
                href="#"
                className="btn theme-btn mt-0"
                data-bs-dismiss="modal"
              >
                Save
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* location offcanvas end */}
      {/* tap to top start */}
      <button className="scroll scroll-to-top">
        <i className="ri-arrow-up-s-line arrow" />
      </button>
      {/* tap to top end */}
      {/* responsive space */}
      <div className="responsive-space" />
      {/* responsive space */}
      {/* bootstrap js */}
      {/* footer accordion js */}
      {/* loader js */}
      {/* swiper js */}
      {/* script js */}
    </>
  );
};

export default Orderdetail;
