import React from "react";
import "./privacy.css";
import Navbar from "../components/Navbar";
import Delivery from "../components/delivery";
import Footer from "../components/Footer";
import FooterMobileMenu from "../components/FooterMobileMenu";
const Terms = () => {
  return (
    <>
      <Navbar /> 
      <section className="section-t-space mytabb overflow-hidden pt-120 ">
        <Delivery /> 
      </section>
      <div className="containers">
        <h1>Terms of Service</h1>
        <p>
        Please read these Terms of Service (“Terms”) set out below carefully before ordering. By ordering any Food from our website or via our mobile applications (“Services”) you agree to be bound by these Terms.
        </p>

        <h2>1. ABOUT US</h2>
        <p>
        1.1.	We are Bites Limited, 6 Shelburne Court, High Wycombe, England, HP12 3NH, “Bites" “we”, “us”, “our”).
        </p>
        <p>
        1.2.	To contact us, please use contact@highwycombebites.co.uk.
        </p>
        <p>
        1.3.	These Terms were last updated on Monday, 12th of August, 2024, and are the current and valid version.
        </p>
        <p>
        1.4.	The Bites Limited, logo, marks, emblems and images are the copyright of Bites Limited, © 2024 All Rights Reserved.
        </p>
        <p>
        1.5.	The following additional terms apply to your use of our Services and form part of these Terms: 
        </p>
        <p>
        1.5.1.	our Privacy Policy, if you are using our website; 
        </p>
        <p>
        1.5.2.	our Cookie Policy, if you are using our website
        </p>
        <p>
        1.5.3.	our App Privacy Policy, if you are using our iOS or android mobile applications; and
        </p>
        <p>
        1.5.4.	our EULA, if you are using our iOS or android mobile applications.
        </p>

        <h2>2.	OUR SERVICES</h2>
        <p>
        Bites Limited, provides on- demand delivery services, via a mobile application or the website (collectively, “Platform”). The Platform connects you with local restaurants (“Partners”). The Platform also connects our Partners to independent contractor drivers to carry out our on-demand delivery services. Through the Platform consumers may request that food is delivered to them from the particular restaurant. Independent contractor drivers can access the Platform and receive delivery opportunities. Bites Limited, is not a retail store, restaurant, food delivery service, merchandise delivery service or food preparation entity.
        </p>

        <h2>3.	PURPOSE</h2>
        <p>
        The purpose of our Service is to provide a simple and convenient service to you, linking you to the Partner’s online Restaurant and if they sell products, allowing you to order those products from them. Bites Limited, via our website and App, on behalf of our Partners, concludes orders on their behalf and delivers the products to you.
        </p>
        <h2>4.	SERVICE AVAILABILITY</h2>
        <p>
        Bites Limited, offers an ordering and delivery service from our Restaurant Partners. Each Partner has a prescribed delivery area. This is to ensure that their products reach your door when they are at their best. If you live outside the delivery areas, a message will appear on screen notifying you that ordering online will not be possible. We do not accept orders from individuals to areas in which we do not have a Restaurant. Operating hours will vary depending on local trading conditions and the availability of our Partners.
        </p>

        <h2>5.	ORDERS</h2>
        <p>
        5.1.	Minimum order values are set by the individual Partners. When you place an order through our Service, an email confirming that your order has been received and accepted by our Partners (the "Confirmation Email") will be sent to you by us, on behalf of the Partner. The contract for the supply of any Order you order through us will be between you and the Partner and will only be formed when you have been sent the Confirmation Email by us. Please ensure that you have given us a correct email address as this is how we will communicate with you about your Order. Please also ensure that you provide an accurate address and telephone number to ensure that your order arrives at the correct location. 
        </p>
        <p>
        5.2.	Most of our Partners will use our delivery service to deliver your goods and these Terms will therefore apply. If you do not open the door or respond to telephonic correspondence within 5 minutes of our delivery drivers physically reaching your address, we reserve the right to leave the premises, and you will be charged for the Order plus delivery. Sometimes, things outside of our control can cause a delay. If this happens, where possible, we will try to contact you if we feel that your order might not arrive within 45 minutes, and we will continue to get it to you as quickly as possible. You can also track your order to see exactly where it is by using the Bites Limited website or app.
        </p>
        <h2>6.	AVAILABILITY AND DELIVERY</h2>
        <p>
        For users using our Bites Limited delivery service, our aim is to provide the best delivery service possible. Unfortunately, things do not always go to plan and factors, such as traffic conditions and weather, may occasionally prevent us from achieving our targets in this regard. We will do our best to ensure that your product order is delivered by the time specified in the email and website or App. The timing of your order is determined by taking into account the number of orders and the circumstances being faced by the Partner at that time.
        </p>

        <h2>7.	ADDITIONS TO YOUR ORDER</h2>

        <p>
        If your order is completed and payment has been accepted, you will have to order the extra items and either pay for a new delivery or arrange to collect.
        </p>

        <h2>8.	CANCELLATION </h2>
        <p>
        8.1.	You may cancel an order without charge at any time before the Restaurant has started preparing the order. If you wish to cancel an order before it becomes a Started Order, please contact us immediately, via our Application. If the Restaurant confirms the order was not a Started Order, we will refund your payment (excluding any discount, that was applied to the order). If you cancel any order after it becomes a Started Order, you will be charged the full price for the Items, and if the driver has been dispatched you will also be charged for delivery.
        </p>
        <p>
        8.2.	Bites Limited and the Restaurant may notify you that an order has been cancelled at any time. You will not be charged for any orders cancelled by us or the Restaurant, and we will reimburse you for any payment already made using the same method you used to pay for your order.
        </p>

        <h2>9.	PRICE AND PAYMENT</h2>
        <p>
        The price of any product will be listed on our Service. Prices include VAT. Prices are liable to change at any time, but changes will not affect orders in respect of which you have been presented with the Confirmation Email, save in the case of an obvious pricing mistake, whereby we will notify you as soon as we can about the pricing issue. You may be able to cancel your order once we notify you. Despite our best efforts, some of the Orders listed on our Service may be incorrectly priced. The relevant Restaurant will normally verify prices as part of the order process. Payment for all products can be made by credit or debit card through our Service. Once your order has been confirmed your credit or debit card will have been authorised and the amount marked for payment. Payment is made directly to Bites Limited and is subsequently passed on by Pickup, to the Partner. We are authorised by our Partners to accept payment on their behalf and payment of the price of any Orders to us will discharge your obligations to pay such price to the Partner. When we make a delivery, we may at our sole discretion charge you a payment processing fee ("Delivery Fee") which will be notified to you before you complete your order.
        </p>

        <h2>10.	ACCESSING OUR SERVICES </h2>
        <p>
        Access to our website and App and to our Service is permitted on a temporary basis, and we reserve the right to withdraw or amend access to our website, App and our Service without notice (see below). We will not be liable if, for any reason, our website, App and our Service is unavailable at any time or for any period. You must be over 18 years of age to order any services on the Bites Limited app. Our drivers will request to see appropriate ID for anyone who appears to be under the age of 18 and will refuse to deliver any goods if appropriate ID has not been shown. From time to time, we may restrict access to some parts of our website, App and our Service, or our entire website, App or Service to users who have registered with us. You are responsible for maintaining the confidentiality of your login details and any activities that occur under your account. If you have any concerns about your login details or think they have been misused, you should contact us straight away to let us know. We can deactivate your account at any time.
        </p>

        <h2>11.	ALLERGIES</h2>
        <p>
        If you have allergies, allergic reactions or dietary restrictions and requirements, please contact us before placing an order.
        </p>

        <h2>12.	ACCEPTABLE USE</h2>
        <p>
        You may use our Service only for lawful purposes. You may not use our website, App and our Service in any way that breaches any applicable local, national, or international law or regulation or to send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards below. You also agree not to access without authority, interfere with, damage, or disrupt any part of our website, App and our Service or any network or equipment used in the provision of our Service.
        </p>

        <h2>13.	FEATURES</h2>
        <p>
        We may from time to time provide certain features which allow you to interact through our website, App, and our Service. Generally, we do not moderate any interactive service we provide although we may remove content in contravention of these Terms. If we do decide to moderate an interactive service, we will make this clear before you use the service and normally provide you with a means of contacting the moderator, should a concern or difficulty arise.
        </p>

        <h2>14.	CONTENT</h2>
        <p>These content standards apply to any and all material which you contribute to our Service (the "Contributions"), and to any interactive services associated with it. You must comply with the spirit of the following standards as well as the letter. The standards apply to each part of any Contributions as well as to its whole. Contributions must be accurate (where they state facts), be genuinely held (where they state opinions) and comply with applicable law. Contributions must not: contain any material which is defamatory of any person, obscene, offensive, hateful or inflammatory, promote sexually explicit material or promote violence or promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; infringe any copyright, database right or trademark of any other person; be likely to deceive any person or be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence or promote any illegal activity; be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety or be likely to harass, upset, embarrass, alarm or annoy any other person; be used to impersonate any person, or to misrepresent your identity or affiliation with any person or give the impression that they emanate from us, if this is not the case; or advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</p>

        <h2>15.	SUSPENSION AND TERMINATION</h2>
        <p>
        15.1.	Failure to comply with our Acceptable Use and Content Standards in these Terms constitutes a material breach of these Terms and may result in our taking all or any of the following actions:
        </p>
        <p>
        15.1.1.	immediate, temporary or permanent withdrawal of your right to use our Service;
        </p>
        <p>
        15.1.2.	immediate, temporary or permanent removal of any posting or material uploaded by you to our Service; 
        </p>
        <p>
        15.1.3.	issuing of a warning to you; 
        </p>
        <p>
        15.1.4.	legal action against you including proceedings for reimbursement of all costs on an (including, but not limited to, reasonable administrative and legal costs) resulting from the breach; 
        </p>
        <p>
        15.1.5.	disclosure of such information to law enforcement authorities as we reasonably feel is necessary. 
        </p>

        <p>
        15.2.	The responses described in this clause are not limited, and we may take any other action we reasonably deem appropriate
        </p>

        <h2>16.	INTELLECTUAL PROPERTY RIGHTS </h2>
        <p>
        We are the owner of or the licensee of all intellectual property rights in our website, App and our Service, and in the material published on it (excluding your Contributions). Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use any content on our site in any way except for your own personal, non-commercial use. 
        </p>

        <h2>17.	INFORMATION POSTED </h2>
        <p>
        Commentary and other materials posted on our Service are not intended to amount to advice on which reliance should be placed. We therefore disclaim all liability and responsibility arising from any reliance placed on such materials by any visitor to our Service, or by anyone who may be informed of any of its contents. 
        </p>

        <h2>18.	CHANGES</h2>
        <p>
        We aim to update our website, App and our Service regularly and may change the content at any time. If the need arises, we may suspend access to our website, App and our Service or close them indefinitely. Any of the material on our website, App and our Service may be out of date at any given time, and we are under no obligation to update such material. 
        </p>

        <h2>19.	INFORMATION ABOUT YOU </h2>
        <p>
        We collect certain data about you as a result of you using our Service. This is described in more detail in our Privacy Policy.
        </p>

        <h2>20.	UPLOADING MATERIAL TO OUR WEBSITE, APP AND OUR SERVICE</h2>
        <p>
        Any material you upload to our Service or data that we collect as set out above will be considered non-confidential and non-proprietary, and you acknowledge and agree that we have the right to use, copy, distribute, sell and disclose to third parties any such material or data for any purpose related to our business. To the extent that such material is protected by intellectual property rights, you grant us a perpetual, worldwide, royalty-free licence to use, copy, modify, distribute, sell and disclose to third parties any such material or data for any purpose related to our business.
        </p>

        <h2>21.	LIMITATION OF LIABILITY</h2>
        <p>
        21.1.	We have taken every care in the preparation of our website, App and our Service. However, we will not be responsible for any errors or omissions in relation to such content or for any technical problems you may experience with our website, App and our Service.
        </p>
        <p>
        21.2.	You agree not to use the Services, our App and the User related content for any resale purposes, and we have no liability to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with these Terms (including but not limited to the use of, or inability to use, the Services, our App or any other website or software) for:
        </p>
        <p>
        21.2.1.	loss of profits, sales, business, or revenue;
        </p>
        <p>
        21.2.2.	business interruption;
        </p>
        <p>
        21.2.3.	loss of anticipated savings;
        </p>
        <p>
        21.2.4.	loss or corruption of data or information;
        </p>
        <p>
        21.2.5.	loss of business opportunity, goodwill or reputation; or
        </p>
        <p>
        21.2.6.	any other indirect or consequential loss or damage.
        </p>
        <p>
        21.3.	Nothing in these Terms shall limit or exclude our liability for:
        </p>
        <p>
        21.3.1.	death or personal injury resulting from our negligence; 
        </p>
        <p>
        21.3.2.	fraud; and/or
        </p>
        <p>
        21.3.3.	any other matter in respect of which we are prohibited under applicable law from limiting or excluding our liability.
        </p>
        <p>
        21.4.	These Terms set out the full extent of our obligations and liabilities in respect of the supply of our website, App and our Service. Except as expressly stated in these Terms, there are no conditions, warranties, representations or other terms, express or implied, that are binding on us. Any condition, warranty, representation or other term concerning the supply of our website, App and our Service which might otherwise be implied into, or incorporated in, these Terms whether by statute or otherwise, is excluded to the fullest extent permitted by law.
        </p>
        <p>
        21.5.	We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your device equipment, device programs, data or other proprietary material due to your use of our website, App and our Service or to your downloading of any content on it, or on any website linked to it.
        </p>
        <p>
        21.6.	This does not affect our liability for death or personal injury arising from our negligence, nor our liability for fraudulent misrepresentation, nor any other liability which cannot be excluded or limited under applicable law.
        </p>

        <h2>22.	OTHER IMPORTANT TERMS </h2>
        <p>
        22.1.	We may transfer our rights and obligations under these Terms to another organisation, but this will not affect your rights or obligations under these Terms. 
        </p>
        <p>
        22.2.	You may only transfer your rights or your obligations under these Terms to another person if we agree in writing.
        </p>
        <p>
        22.3.	No joint venture, partnership or agency or employment relationship has arisen by reason of these Terms.
        </p>
        <p>
        22.4.	These Terms and any document expressly referred to in it constitutes the entire agreement between us.
        </p>
        <p>
        22.5.	If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you. 
        </p>
        <p>
        22.6.	Each of the conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect. 
        </p>
        <p>
        22.7.	These Terms, its subject matter and its formation, and any other disputes or claims in connection therewith, are governed by the laws of England and Wales. In the event of any such disputes or claims in connection with these Terms, you agree to first engage in good faith discussions with us to resolve such dispute or claim. If such dispute or claim is not resolved within sixty (60) days, we both irrevocably submit to the exclusive jurisdiction of the courts of England and Wales.
        </p>
        
      </div>
      <FooterMobileMenu/>
      <Footer/>
    </>
  );
};

export default Terms;