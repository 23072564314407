import React ,{useEffect,useState} from 'react'
import { getFaq } from '../utils/api'
const FaqCom = () => {  

  const [faqItems,setFaqItems] = useState([])
  const [openIndex, setOpenIndex] = useState(null);  

const getdata =async()=>{
  const faqs = await getFaq()
  setFaqItems(faqs.faqItems)
  console.log(faqs.faqItems,"faqs")
}

useEffect(() => {
  getdata()

},[])
const handleToggle = (index) => {
  setOpenIndex(openIndex === index ? null : index);
};
  return (
    <div>
                   {faqItems.map((items,index)=>(
            <div className="accordion-item" key={items.questionId}>
              <h2 className="accordion-header">
                <button
             className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
             type="button"
             onClick={() => handleToggle(index)}
             aria-expanded={openIndex === index}
                >
                  {items.question}
                  {/* {console.log(items.questionId,"faq")} */}
                </button>
              </h2>
               <div
               id={`flush-collapse${index}`}
               className={`accordion-collapse collapse ${openIndex === index ? 'show' : ''}`}
               data-bs-parent="#accordionFlushExample"
             >
                <div className="accordion-body">
                  {items.answer}
                </div>
              </div>
            </div>
                        ))} 
    </div>
  )
}

export default FaqCom