import React from "react";
import "./privacy.css";
import Navbar from "../components/Navbar";
import Delivery from "../components/delivery";
import Footer from "../components/Footer";
import FooterMobileMenu from "../components/FooterMobileMenu";
const Cookie = () => {
  return (
    <>
      <Navbar /> 
      <section className="section-t-space mytabb overflow-hidden pt-120 ">
        <Delivery /> 
      </section>
      <div className="containers">
        <h1>Cookie Policy</h1>
        <p>
        This Cookie Policy applies to our use of cookies and other similar technologies on our website at www.highwycombebites.co.uk. The purpose of this policy is to provide you with information about what cookies are and how they work. It also explains what cookies we use, what they do and how you can control what cookies we use when you visit our website
        </p>

        <h2>WHO IS THE DATA CONTROLLER?</h2>
        <p>
        A “data controller” is a person or organisation who alone or jointly determines the purposes for which, and the manner in which, any personal data is, or is likely to be, processed. In this sense, Bites Limited, 6 Shelburne Court, High Wycombe, England, HP12 3NH, “Bites","we", "us", ""our " is the data controller. If you have any questions about cookies or about data protection at [Bites] in general, you can reach us by email using contact@highwycombebites.co.uk        </p>

        <h2>WHY DO WE HAVE A COOKIE POLICY?</h2>
        <p>
        In accordance with the UK’s Privacy and Electronic Communications Regulations (“PECR”) and the EU`s Privacy and Electronic Communications Directive (“PECD”), we need to inform you about the cookies we use and obtain your consent when using certain types of cookies namely Functional, Performance, Analytics and Advertising Cookies.        
        </p>

        <h2>WHAT IS A COOKIE?</h2>
        <p>
        Cookies are text files containing small amounts of information which are downloaded to your device (e.g. computer, smartphone, electronic device which accesses the internet) when you visit a website. Cookies can be useful because they allow a website to recognise a user’s device, preferences and generally help to improve your online experience.       
         </p>
        
        <h2>WHAT TYPES OF COOKIES DO WE USE?</h2>
        <p>
        There are three types of cookies that we use, Necessary Cookies, Analytics Cookies and Marketing Cookies. These cookies perform different functions as explained below above the relevant cookies sections.        
        </p>
        <ul>
          <li>Necessary Cookies</li>
          <p>Necessary cookies are essential in order to provide you with the services you request on our website and in order that we can comply with legal obligations. We do not need to ask for your consent in order to use these cookies as without them we would not be able to provide the services requested by you. These are always active on the Website. 
        </p>
        <li>	Analytics Cookies</li>
        <p>
        Analytics cookies collect information about things like how many people visit our Website, what parts of the Website people look at and how many new visitors we have to the Website. These cookies help us to monitor how effective our Website is and to improve it, tailoring it to our visitors. Analytics cookies are only used if you consent to it in our Cookie Consent Tool.
        </p>
        <li>Marketing/Advertising Cookies</li>
        <p>
        Marketing cookies help us provide you with personalised and relevant services or advertising, and track the effectiveness of our digital marketing activities. They are capable of tracking your browser across other websites and building up a profile of your interests. This may impact the content and messages you see on other websites you visit. Advertising cookies are only used if you consent to it in the Cookie Consent Tool.
        </p>
          </ul>

        <h2>CONSENT</h2>
        <p>
        Necessary Cookies will always be active on your device, but we ask for your consent to place Analytics Cookies and other similar technologies on your device. If at any time you want to change your cookie settings, including withdrawing your consent to this processing, we would encourage you to do this via the links below. You may need to refresh the page before the changes to the settings take effect.
        </p>
        
        <h2>THE COOKIES WE USE</h2>
        <p><strong>Google Analytics</strong></p>
        <p>
        We use Google Analytics, a web analysis service of <a href="https://policies.google.com/privacy?hl=en-US">Google</a> . Google Analytics uses a specific form of cookie, which is stored on your device and enables an analysis of your use of our website. The cookies set by Google Analytics for measurement are different for each visitor (i.e. there is not a single Google Analytics cookie ID that is used on all sites using Google Analytics). The information about your use of our website generated by the cookie is generally transmitted to a Google server in the USA and stored there. 
        </p>

        <p>
        Google uses this information on our behalf to analyse your use of this website in order to compile reports on website activities and provide additional services related to website and internet use. The IP address transmitted by your browser in the context of Google Analytics is not merged with other data. In the configuration of Google Analytics, we ensured that Google receives this data as a processor and is therefore not allowed to use this data for its own purposes. 
        </p>

        <p>
        In relation to the data transfer into the USA Google’s processing agreement for Google Analytics, can be read <a href="https://support.google.com/analytics/answer/3379636?hl=en">here</a> . You can disable tracking by Google Analytics with future effect by downloading and installing the Google Analytics Opt-out Browser Add-on for your current web browser following this <a href="http://tools.google.com/dlpage/gaoptout?hl=en">link</a>.
        </p>
        
        <p>
        <strong>Pixel Cookies and Tags</strong>
        </p>

        <p>
        We use so-called Pixel Cookies and Tags (“Pixel Cookies”). A Pixel Cookie is an advertising tool and typically consists of a JavaScript code snippet that allows us to understand and track visitors' activity on our website. For this purpose, Pixel Cookies collect and process information about visitors of our website and the device used (so-called event data).
        </p>
        <p>
        Event data collected through Pixel Cookies is used for targeting our advertisements and improving ad delivery and personalised advertising. For this purpose, the event data collected on our website by means of Pixel Cookies is transmitted to the relevant operator of the Pixel Cookie and in part, also stored on your device. However, this only happens with your consent, and we and the relevant operator of the Pixel Cookie are considered joint controllers. Nonetheless, for the subsequent processing of the transmitted Event Data, the relevant operator of the Pixel Cookie is the sole controller.
        </p>

        <p>
        For more information about how the relevant operator of the Pixel Cookie processes personal data, including the legal basis on which they rely on and how you can exercise your rights against, please refer to the following Privacy Policies:  <a href="https://www.facebook.com/privacy/policy/version/20220104/?paipv=0&eav=AfbVpwBpEPEySvmi2m1knhTvvmIfHixxJ9_8RJ5IRM1FpsIIfZAbytq1jw1bJkHjj64&wtsid=rdr_0w6luTHGqD3dOgV5k&_rdr">Facebook</a> , <a href="https://help.instagram.com/155833707900388">Instagram</a>, and <a href="https://www.tiktok.com/legal/page/us/privacy-policy/en">TikTok</a>.   
        </p>

        <p><strong>Google Ads</strong></p>
        <p>
        We use Google Ads, an online advertising service provided by <a href="https://policies.google.com/privacy?hl=en-US">Google</a>. Google Ads enables us to show advertisements in the Google search engine or on third-party websites when the user enters certain search terms on Google (keyword targeting). Furthermore, targeted advertisements can be shown on the basis of user data available to Google (e.g. location data and interests) (target group targeting). With the help of Google Ads, we can evaluate this data quantitatively, for example, which search terms have led to the display of our advertisements and how many ads have resulted in corresponding clicks. The use of this service is based on your consent.
        </p>

        <p><strong>Google Ads Remarketing</strong></p>
        <p>
        We also use the remarketing functions Google Ads. Google Ads Remarketing allows us to assign people who interact with our advertisements and website to specific target groups and to display interest-based advertising to them within the Google advertising network.
        </p>

        <p>
        Further, the advertising target groups created by us using Google Ads Remarketing can be linked to Google's cross-device functions. This means that interest-based, personalised advertising (that is advertising that has been adapted to you depending on your previous usage and surfing behaviour) can also be displayed on another of your end devices (e.g. tablet or PC). The use of this service is based on your consent.
        </p>

        <p><strong>Google Conversion Tracking</strong></p>
        <p>We also use Google Conversion Tracking. With the help of Google conversion tracking, we and Google can recognise whether the user has performed certain actions. For example, we can evaluate which buttons on our website were clicked how often and which products were viewed or purchased particularly frequently. This information is used to create conversion statistics. We also learn the total number of users who clicked on our ads and what actions they took. We do not receive any information with which we can personally identify the user. Google itself uses cookies or comparable recognition technologies for identification. The use of this service is based on your consent.
         </p>
        
        <h2>HOW TO BLOCK COOKIES AND THE CONSEQUENCES OF THIS</h2>
        <p>
        If you want to block all cookies, you may be able to do this through the settings in your browser for each browser you use and each device you use to access the internet ( <a href="https://support.google.com/chrome/answer/95647?hl=en-GB&co=GENIE.Platform%3DDesktop">Google Chrome</a>, <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">Mozilla Firefox</a>,<a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"> Microsoft Edge</a>, <a href="https://www.opera.com/use-cases/clean-browser-and-remove-trackers">Opera</a>, <a href="https://support.apple.com/en-gb/HT201265">Safari</a>). 
        </p>

        <p>
        We also offer a Cookie Consent Tool when you first visit our website. This tool allows you to specify your preference about cookies. You can accept or reject them or access this Cookie Policy before giving your consent to cookies or rejecting them. This permits you to make an informed decision about the cookies we use. You are free to accept or reject cookies, but note that after rejecting them, browsing our website might be less user-friendly and the relevant content might be affected.
        </p>
       
       <p>
       In addition, if you do not wish to participate in advertising personalisation or retargeting/tracking you can object to behavioural advertising at the following websites: <a href="https://www.youronlinechoices.com/">Your Online Choices</a>, <a href="https://youradchoices.ca/">Digital Advertising Alliance of Canada </a>, <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative</a>,<a href="https://youradchoices.com/"> AdChoices </a> the <a href="https://www.youronlinechoices.eu/"> European Interactive Digital Advertising Alliance (Europe only)</a>, and <a href="https://www.google.com/settings/ads/onweb/">Google Ad Settings</a>,
       </p>

       <h2>HELP AND COMPLAINTS</h2>
       <p>
       If you have any questions about this policy or the information we hold about you please contact us by email using contact@highwycombebites.co.uk.
       </p>
       <h2>
       CHANGES
       </h2>
       <p>
       The first version of this policy was issued on Monday, 12th of August, 2024 and is the current version. Any prior versions are invalid and if we make changes to this policy, we will revise the effective date. 
       </p>
        
      </div>
      <FooterMobileMenu/>
      <Footer/>
    </>
  );
};

export default Cookie;