import React from "react";
import { useEffect } from "react";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import Navbar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {getCategorylist, getDining, getOfferRest, getRestaurant, getTopRest } from "../store/feature/restaurantSlice";
import RestaurantCategory from "../components/RestaurantCategory";
import Footer from "../components/Footer";
import FooterMobileMenu from "../components/FooterMobileMenu";
import Delivery from "../components/delivery";
import { Link } from "react-router-dom";
import CategoryFoods from "../components/CategoryFoods";
import restImage from "../assets/offerspage.jpg"

const Offers = () =>{
    useEffect(() => {
        new Swiper(".swiper-food", {
          modules: [Navigation, Pagination],
          navigation: {
            nextEl: ".next-rest-1",
            prevEl: ".prev-rest-1",
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          slidesPerView: 3,
          spaceBetween: 10,
          breakpoints: {
            576: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 6,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 7,
              spaceBetween: 10,
            },
          },
        });
      }, []);
    useEffect(() => {
        new Swiper(".swiper-d", {
          modules: [Navigation, Pagination],
          navigation: {
            nextEl: ".popular-next",
            prevEl: ".popular-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          slidesPerView: 1,
          spaceBetween: 10,
          breakpoints: {
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
          },
        });
      }, []);

      // _________________________________
      const storedAddress = JSON.parse(localStorage.getItem("userAddress"));
      const storedPincode = JSON.parse(localStorage.getItem("pincode")) || "";
      const pincode = storedPincode ? storedPincode.longName : "";
      const dispatch = useDispatch();
      const { restaurant,diningRestaurant,topRestaurant, offeredRestaurant,categorylist } = useSelector((store) => store.restaurant);
      useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
      useEffect(() => {
        if (storedAddress ) {
       const data = {
          lat: storedAddress?.lat,
          lng: storedAddress?.lng,
          // lat: "22.7195687",
          // lng: "75.8577258",

          pincode: pincode.toString() || "",
        };
        // console.log("storedAddress",data);
        dispatch(getRestaurant(data));
        dispatch(getDining(data));
        dispatch(getTopRest(data));
        dispatch(getOfferRest(data));
      }}, []);

      useEffect(() => {
        console.log("diningRestaurant", diningRestaurant);
        console.log("topRestaurant", topRestaurant);
        console.log("offeredRestaurant", offeredRestaurant);
      }, [restaurant,diningRestaurant]);
      

      useEffect(() => {
        const catData = {
          sortingtype: "1"
        };
        dispatch(getCategorylist(catData));
      }, [dispatch]); // You can add dependencies if needed
    return(
        <>
        <Navbar/>
  
  {/* Header Section end */}
  <section className="section-t-space mytabb overflow-hidden pt-120">
    {/* <div className="container text-center">
      <div className="tab">
        <div>
          <Link className="tablinks active">
            <p>
              <i className="fa fa-motorcycle" /> Delivery
            </p>{" "}
            <p className="smtext">35 - 50 Min</p>
          </Link>
          <Link className="tablinks">
            <p>
              <i className="fa fa-shopping-bag" aria-hidden="true" /> Collection{" "}
            </p>{" "}
            <p className="smtext">15 - 25 Min</p>
          </Link>
        </div>
      </div>
    </div> */}
    <Delivery/>
  </section>
  {/* <section className="page-head-section">
    <div className="container page-heading">
      <h2 className="h3 mb-3 text-white text-center">Offers</h2>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
          <li className="breadcrumb-item">
            <Link href="index.html">
              <i className="ri-home-line" />
              Home
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Offers
          </li>
        </ol>
      </nav>
    </div>
  </section> */}


<section
  className="page-head-section"
  style={{
    position: 'relative',
    padding: '100px 0px 100px 0px',
    backgroundColor: '#00000085',
    backgroundImage: `url(${restImage})`,
    // backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }}
>
  <div className="container page-heading">
    <h2 className="h3 mb-3 text-white text-center" style={{display:"none"}}>All Restaurant</h2>
    {/* Breadcrumb removed */}
  </div>
     </section>
  {/* categories section starts */}
  {/* <div className="categories-section mt-5 section-t-space section-b-space">
    <div className="container">
      <div className="position-relative">
        <div className="title title-sm mt-0">
          <h2>Offer By Dishes</h2>
          <div className="loader-line" />
        </div>
        <div className="swiper swiper-food categories-no-arrow categories-style">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <Link to={"/Restaurantlist"} className="food-categories">
                <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-1.png"
                  alt="p-1"
                />
                <h4 className="dark-text">Pizza</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-2.png"
                  alt="p-2"
                />
                <h4 className="dark-text">Chicken</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-3.png"
                  alt="p-3"
                />
                <h4 className="dark-text">Burger</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-5.png"
                  alt="p-5"
                />
                <h4 className="dark-text">Fries</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-6.png"
                  alt="p-6"
                />
                <h4 className="dark-text">Boritto</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-7.png"
                  alt="p-7"
                />
                <h4 className="dark-text">Taco</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-8.png"
                  alt="p-8"
                />
                <h4 className="dark-text">Muffin</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-9.png"
                  alt="p-9"
                />
                <h4 className="dark-text">Meat</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-10.png"
                  alt="p-5"
                />
                <h4 className="dark-text">Panner</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-11.png"
                  alt="p-11"
                />
                <h4 className="dark-text">Hotdog</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-12.png"
                  alt="p-12"
                />
                <h4 className="dark-text">Donuts</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-13.png"
                  alt="p-13"
                />
                <h4 className="dark-text">Coffee</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-14.png"
                  alt="p-14"
                />
                <h4 className="dark-text">Sandwich</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-15.png"
                  alt="p-15"
                />
                <h4 className="dark-text">Noddle</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-16.png"
                  alt="p-16"
                />
                <h4 className="dark-text">Pasta</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-17.png"
                  alt="p-17"
                />
                <h4 className="dark-text">Meggi</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-18.png"
                  alt="p-18"
                />
                <h4 className="dark-text">Momos</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-19.png"
                  alt="p-19"
                />
                <h4 className="dark-text">Salad</h4>
              </Link>
            </div>
            <div className="swiper-slide">
            <Link to={"/Restaurantlist"} className="food-categories">
            <img
                  className="img-fluid categories-img"
                  src="assets/images/product/p-20.png"
                  alt="p-20"
                />
                <h4 className="dark-text">Biryani</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
          <CategoryFoods data={categorylist} text={"Offer By Dishes"} />

  {/* categories section end */}
  {/* Featured Restaurants section starts */}
  {
     offeredRestaurant ?
     <RestaurantCategory data={offeredRestaurant?.offerrest} text={'Popular Offers'} num={"7"}/>
     :""
   }
  {/* <section className="restaurant-list section-b-space  ratio3_2">
    <div className="container">
      <div className="popular-restaurant section-md-t-space ratio3_2">
        <div className="title title-sm mt-0">
          <h2>Popular Restaurants</h2>
          <div className="loader-line" />
        </div>
        <div className="theme-arrow">
          <div className="swiper swiper-d  popular-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="vertical-product-box product-style-2">
                  <div className="vertical-product-box-img">
                    <Link href="restaurant-details.html">
                      <img
                        className="product-img-top w-100 bg-img"
                        src="assets/images/product/vp-12.png"
                        alt="vp1"
                      />
                    </Link>
                  </div>
                  <div className="vertical-product-body">
                    <div className="brand-label">
                      <img
                        src="assets/images/icons/brand1.png"
                        className="img-fluid"
                        alt="brand"
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Link href="restaurant-details.html">
                        <h4 className="vertical-product-title">
                          Cozy Cuppa Cafe
                        </h4>
                      </Link>
                      <h6 className="rating-star">
                        <span className="star">
                          <i className="ri-star-s-fill" />
                        </span>
                        3.2
                      </h6>
                    </div>
                    <h6 className="food-items">
                      <span>Italian</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Dips</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Deserts</span>
                    </h6>
                    <ul className="details-list">
                      <li>
                        <i className="ri-map-pin-fill theme-color" /> 2.5 km
                      </li>
                      <li>
                        <i className="ri-time-line" /> 20-30 min
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="vertical-product-box product-style-2">
                  <div className="seller-badge">
                    <img
                      className="img-fluid badge"
                      src="assets/images/svg/medal-fill.svg"
                      alt="medal"
                    />
                    <h6>Exclusive</h6>
                  </div>
                  <div className="vertical-product-box-img">
                    <Link href="restaurant-details.html">
                      <img
                        className="product-img-top w-100 bg-img"
                        src="assets/images/product/vp-11.png"
                        alt="vp1"
                      />
                    </Link>
                  </div>
                  <div className="vertical-product-body">
                    <div className="brand-label">
                      <img
                        src="assets/images/icons/brand2.png"
                        className="img-fluid"
                        alt="brand"
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Link href="restaurant-details.html">
                        <h4 className="vertical-product-title">
                          Master's Cafe
                        </h4>
                      </Link>
                      <h6 className="rating-star">
                        <span className="star">
                          <i className="ri-star-s-fill" />
                        </span>
                        3.5
                      </h6>
                    </div>
                    <h6 className="food-items">
                      <span>Italian</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Dips</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Deserts</span>
                    </h6>
                    <ul className="details-list">
                      <li>
                        <i className="ri-map-pin-fill theme-color" /> 2.5 km
                      </li>
                      <li>
                        <i className="ri-time-line" /> 25-30 min
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="vertical-product-box product-style-2">
                  <div className="vertical-product-box-img">
                    <Link href="restaurant-details.html">
                      <img
                        className="product-img-top w-100 bg-img"
                        src="assets/images/product/vp-10.png"
                        alt="vp1"
                      />
                    </Link>
                  </div>
                  <div className="vertical-product-body">
                    <div className="brand-label">
                      <img
                        src="assets/images/icons/brand3.png"
                        className="img-fluid"
                        alt="brand"
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Link href="restaurant-details.html">
                        <h4 className="vertical-product-title">
                          Ribeye Junction
                        </h4>
                      </Link>
                      <h6 className="rating-star">
                        <span className="star">
                          <i className="ri-star-s-fill" />
                        </span>
                        3.7
                      </h6>
                    </div>
                    <h6 className="food-items">
                      <span>Italian</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Dips</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Deserts</span>
                    </h6>
                    <ul className="details-list">
                      <li>
                        <i className="ri-map-pin-fill theme-color" /> 2.5 km
                      </li>
                      <li>
                        <i className="ri-time-line" /> 5-10 min
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="vertical-product-box product-style-2">
                  <div className="seller-badge exclusive-badge">
                    <img
                      className="img-fluid badge"
                      src="assets/images/svg/crown.svg"
                      alt="medal"
                    />
                    <h6>Best seller</h6>
                  </div>
                  <div className="vertical-product-box-img">
                    <Link href="restaurant-details.html">
                      <img
                        className="product-img-top w-100 bg-img"
                        src="assets/images/product/vp-9.png"
                        alt="vp1"
                      />
                    </Link>
                  </div>
                  <div className="vertical-product-body">
                    <div className="brand-label">
                      <img
                        src="assets/images/icons/brand4.png"
                        className="img-fluid"
                        alt="brand"
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Link href="restaurant-details.html">
                        <h4 className="vertical-product-title">
                          Poultry Palace
                        </h4>
                      </Link>
                      <h6 className="rating-star">
                        <span className="star">
                          <i className="ri-star-s-fill" />
                        </span>
                        4.2
                      </h6>
                    </div>
                    <h6 className="food-items">
                      <span>Italian</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Dips</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Deserts</span>
                    </h6>
                    <ul className="details-list">
                      <li>
                        <i className="ri-map-pin-fill theme-color" /> 2.5 km
                      </li>
                      <li>
                        <i className="ri-time-line" /> 25-30 min
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="vertical-product-box product-style-2">
                  <div className="vertical-product-box-img">
                    <Link href="restaurant-details.html">
                      <img
                        className="product-img-top w-100 bg-img"
                        src="assets/images/product/vp-8.png"
                        alt="vp1"
                      />
                    </Link>
                  </div>
                  <div className="vertical-product-body">
                    <div className="brand-label">
                      <img
                        src="assets/images/icons/brand7.png"
                        className="img-fluid"
                        alt="brand"
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Link href="restaurant-details.html">
                        <h4 className="vertical-product-title">Wing Master</h4>
                      </Link>
                      <h6 className="rating-star">
                        <span className="star">
                          <i className="ri-star-s-fill" />
                        </span>
                        4.5
                      </h6>
                    </div>
                    <h6 className="food-items">
                      <span>Italian</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Dips</span>
                      <i
                        className="fa fa-circle ml-2 me-2"
                        aria-hidden="true"
                      />
                      <span>Deserts</span>
                    </h6>
                    <ul className="details-list">
                      <li>
                        <i className="ri-map-pin-fill theme-color" /> 2.5 km
                      </li>
                      <li>
                        <i className="ri-time-line" /> 25-30 min
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-button-next popular-next" />
          <div className="swiper-button-prev popular-prev" />
        </div>
      </div>
    </div>
  </section> */}
  {/* featured Restaurants section end */}
  {/* footer section starts */}
  <Footer/>
  {/* footer section end */}
  {/* mobile fix menu start */}
  <FooterMobileMenu/>
  {/* mobile fix menu end */}
  {/* location offcanvas start */}
  <div
    className="modal fade location-modal"
    id="location"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">
            <h5 className="fw-semibold">Select a Location</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="search-section">
            <form className="form_search" role="form">
              <input
                type="search"
                placeholder="Search Location"
                className="nav-search nav-search-field"
              />
            </form>
          </div>
          <Link href="" className="current-location">
            <div className="current-address">
              <i className="ri-focus-3-line focus" />
              <div>
                <h5>Use current-location</h5>
                <h6>Wellington St., Ottawa, Ontario, Canada</h6>
              </div>
            </div>
            <i className="ri-arrow-right-s-line arrow" />
          </Link>
          <h5 className="mt-sm-3 mt-2 fw-medium recent-title dark-text">
            Recent Location
          </h5>
          <Link href="" className="recent-location">
            <div className="recant-address">
              <i className="ri-map-pin-line theme-color" />
              <div>
                <h5>Bayshore</h5>
                <h6>kingston St., Ottawa, Ontario, Canada</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="modal-footer">
          <Link href="#" className="btn gray-btn" data-bs-dismiss="modal">
            Close
          </Link>
          <Link href="#" className="btn theme-btn mt-0" data-bs-dismiss="modal">
            Save
          </Link>
        </div>
      </div>
    </div>
  </div>
  {/* location offcanvas end */}
  {/* tap to top start */}
  <button className="scroll scroll-to-top">
    <i className="ri-arrow-up-s-line arrow" />
  </button>
  {/* tap to top end */}
  {/* responsive space */}
  {/* responsive space */}
  {/* bootstrap js */}
  {/* footer accordion js */}
  {/* loader js */}
  {/* swiper js */}
  {/* script js */}

        
        </>
    )
}


export default Offers