import React from "react";
import { Link } from "react-router-dom";

const FooterMobileMenu = ({ selected }) => {
  return (
    <div className="mobile-menu d-md-none d-block mobile-cart">
      <ul>
        <li className={selected === "home" ? `active` : ""}>
          <Link to={'/'} className="menu-box">
            <i className="ri-home-4-line" />
            <span>Home</span>
          </Link>
        </li>
        <li className={selected === "Dining" ? `active mobile-category` : "mobile-category"} >
          <Link to={'/dining'} className="menu-box">
            <i className="fa fa-cutlery mb-2 pt-1" />
            <span>Dining</span>
          </Link>
        </li>
        <li className={selected === "History" ? `active` : ""}>
          <Link to={'/myhistory'} className="menu-box">
            <i className="ri-apps-line" />
            <span>History</span>
          </Link>
        </li>
        <li className={selected === "setting" ? `active` : ""}>
          <Link to={'/setting'} className="menu-box">
            <i className="fa fa-user mb-2 pt-1" />
            <span>Setting</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default FooterMobileMenu;
