import React from 'react';

const NotFound = () => {
    return (
        <div className=''>
           <h1>404</h1>
           <h3>Page not found</h3>
        </div>
    );
}

export default NotFound;
