import React,{useEffect} from 'react'
import Navbar from '../components/Navbar'
import Delivery from '../components/delivery'
import Footer from '../components/Footer'
import FooterMobileMenu from '../components/FooterMobileMenu'
import { Link } from 'react-router-dom'
const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);
  return (
<>
  {/* Header section start */}
<Navbar/>
  {/* Header Section end */}
  <section className="section-t-space section-b-space mytabb overflow-hidden pt-120">
    {/* <div className="container text-center">
      <div className="tab">
        <div>
          <Link className="tablinks active">
            <i className="fa fa-motorcycle" /> Delivery{" "}
          </Link>
          <Link className="tablinks">
            <i className="fa fa-shopping-bag" aria-hidden="true" /> Collection
          </Link>
        </div>
      </div>
    </div> */}
    <Delivery/>
  </section>
  <section className="page-head-section">
    <div className="container page-heading">
      <h2 className="h3 mb-3 text-white text-center">Contact Us</h2>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
          <li className="breadcrumb-item">
            <Link href="index.html">
              <i className="ri-home-line" />
              Home
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Contact Us
          </li>
        </ol>
      </nav>
    </div>
  </section>
  <section className="section-b-space bg-color">
    <div className="container">
      <div className="title animated-title">
        <div className="loader-line" />
        <div className="d-flex align-items-center justify-content-between flex-wrap w-100">
          <div>
            <h2>Contact Us For Support</h2>
            <h6>Contact us if you have any queries.</h6>
          </div>
        </div>
      </div>
      <div className="contact-detail">
        <div className="row g-4">
          <div className="col-xxl-3 col-md-6">
            <div className="contact-detail-box">
              <div className="contact-icon">
                <i className="ri-phone-fill" />
              </div>
              <div>
                <div className="contact-detail-title">
                  <h4>Phone</h4>
                </div>
                <div className="contact-detail-contain">
                  <p>(+X) XXX XXXX XXXX</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-md-6">
            <div className="contact-detail-box">
              <div className="contact-icon">
                <i className="ri-mail-open-fill" />
              </div>
              <div>
                <div className="contact-detail-title">
                  <h4>Email</h4>
                </div>
                <div className="contact-detail-contain">
                  <p>test@mail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-4">
        <div className="col-xl-12">
          <div className="contact-form">
            <form className="row g-3">
              <div className="col-md-6">
                <label htmlFor="inputFirstname" className="form-label mt-0">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputFirstname"
                  placeholder="Enter your fist name"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputLastname" className="form-label mt-0">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputLastname"
                  placeholder="Enter your last name"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputEmail" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  placeholder="Enter your email"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputPhone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="inputPhone"
                  placeholder="Enter your number"
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="inputtext" className="form-label">
                  How Can We Help You?
                </label>
                <textarea
                  className="form-control"
                  id="inputtext"
                  rows={3}
                  placeholder="Hi there, I would like to...."
                  defaultValue={""}
                />
              </div>
            </form>
            <div className="buttons d-flex align-items-center justify-content-end gap-3">
              <Link href="contact.html.html" className="btn gray-btn mt-0">
                CANCEL
              </Link>
              <Link href="index.html" className="btn theme-btn mt-0">
                SUBMIT
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* footer section starts */}
 <Footer/>
  {/* footer section end */}
  {/* mobile fix menu start */}
<FooterMobileMenu/>
  {/* mobile fix menu end */}
  {/* location offcanvas start */}
  <div
    className="modal fade location-modal"
    id="location"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">
            <h5 className="fw-semibold">Select a Location</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="search-section">
            <form className="form_search" role="form">
              <input
                type="search"
                placeholder="Search Location"
                className="nav-search nav-search-field"
              />
            </form>
          </div>
          <Link href="" className="current-location">
            <div className="current-address">
              <i className="ri-focus-3-line focus" />
              <div>
                <h5>Use current-location</h5>
                <h6>Wellington St., Ottawa, Ontario, Canada</h6>
              </div>
            </div>
            <i className="ri-arrow-right-s-line arrow" />
          </Link>
          <h5 className="mt-sm-3 mt-2 fw-medium recent-title dark-text">
            Recent Location
          </h5>
          <Link href="" className="recent-location">
            <div className="recant-address">
              <i className="ri-map-pin-line theme-color" />
              <div>
                <h5>Bayshore</h5>
                <h6>kingston St., Ottawa, Ontario, Canada</h6>
              </div>
            </div>
          </Link>
        </div>
        <div className="modal-footer">
          <Link href="#" className="btn gray-btn" data-bs-dismiss="modal">
            Close
          </Link>
          <Link href="#" className="btn theme-btn mt-0" data-bs-dismiss="modal">
            Save
          </Link>
        </div>
      </div>
    </div>
  </div>
  {/* location offcanvas end */}
  {/* tap to top start */}
  <button className="scroll scroll-to-top">
    <i className="ri-arrow-up-s-line arrow" />
  </button>
  {/* tap to top end */}
  {/* responsive space */}
  <div className="responsive-space" />
  {/* responsive space */}
  {/* bootstrap js */}
  {/* footer accordion js */}
  {/* loader js */}
  {/* swiper js */}
  {/* script js */}
</>
  )
}

export default Contact