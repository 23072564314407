import React from 'react'
import blog1 from "../../assets/blog1.jpg"

const BlogsBanner = () => {

    return (
        <section
            id="home"
            className="home-wrapper home2 section-b-space overflow-hidden"
            style={{
                display: "block !important",
                position: "relative",
                backgroundImage:
                    `url(${blog1})`,
                padding: "50px 0px !important"
            }}
        >
            <div
                style={{
                    background: "#00000061",
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100%"
                }}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 position-relative">
                        <h2 style={{ fontWeight: "bold", fontSize: 44 }} className="mb-3">
                        Making a Difference for High Wycombe
                        </h2>
                        <h6 className="text-white">
                        Explore our latest blog posts, we take pride in connecting our community through food.

                        </h6>
                    </div>
                    <div className="col-lg-5 col-12"></div>
                </div>
            </div>
        </section>
    )
}

export default BlogsBanner