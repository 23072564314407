import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { insertSupportedBybites } from '../../utils/api';

const SupportedForm = () => {
    const formRef = useRef(null);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        bussinessname: '',
        bussinessaddress: '',
        bussinesstype: '',
        acknowledge: false,
    });

    const [errors, setErrors] = useState({});

    // Close modal when clicking outside the form
   
    const validate = () => {
        const newErrors = {};

        if (!formData.firstName.trim()) newErrors.firstName = 'First Name is required.';
        if (!formData.lastName.trim()) newErrors.lastName = 'Last Name is required.';
        if (!formData.phone.trim()) newErrors.phone = 'Phone number is required.';
        else if (!/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Phone number must be 10 digits.';
        if (!formData.email.trim()) newErrors.email = 'Email is required.';
        else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) newErrors.email = 'Invalid email format.';
        if (!formData.bussinessname.trim()) newErrors.bussinessname = 'Business Name is required.';
        if (!formData.bussinessaddress.trim()) newErrors.bussinessaddress = 'Business Address is required.';
        if (!formData.bussinesstype.trim()) newErrors.bussinesstype = 'Business Type is required.';
        if (!formData.acknowledge) newErrors.acknowledge = 'You must agree to the terms and conditions.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            Swal.fire({
                icon: 'error',
                title: 'Form Submission Failed',
                text: 'Please correct the errors and try again.',
            });
            return;
        }
        try {
            const res = await insertSupportedBybites(formData);
            console.log("Response", res);
        } catch (error) {
            console.error("Unable to Process.");
        }

        Swal.fire({
            icon: 'success',
            title: 'Application Submitted',
            text: 'Your application has been submitted successfully!',
        });

        setFormData({
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            bussinessname: '',
            bussinessaddress: '',
            bussinesstype: '',
            acknowledge: false,
        });
    };

    return (
<div className="contain" id="contain" style={{paddingBottom:"0px"}}>
  <div className="py-3">
    <div className="row">
      <div className="col-12 col-md-6 logo-section d-flex align-items-center justify-content-start mb-3 mb-md-0 d-none d-md-flex">
        <h2 className="text-white text-center text-md-start">
          Enter Your Business Details & Sign Up Today.
        </h2>
      </div>

      <div className="col-12 col-md-6">
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="d-flex flex-column align-items-center"
        >
          {[
            { label: "First Name", name: "firstName", type: "text", placeholder: "First Name" },
            { label: "Last Name", name: "lastName", type: "text", placeholder: "Last Name" },
            { label: "Phone Number", name: "phone", type: "text", placeholder: "Phone Number" },
            { label: "Email", name: "email", type: "email", placeholder: "Email" },
            { label: "organisation  Name", name: "bussinessname", type: "text", placeholder: "organisation  Name" },
            { label: "Business Address", name: "bussinessaddress", type: "text", placeholder: "Address" },
          ].map(({ label, name, type, placeholder }) => (
            <div className="mb-3 w-100" key={name}>
              <label className="form-label text-light">{label} *</label>
              <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                className={`form-control ${errors[name] ? "is-invalid" : ""}`}
                placeholder={placeholder}
              />
              {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
            </div>
          ))}
          {/* <div className="mb-3 w-100">
            <label className="form-label text-light">Business Type *</label>
            <select
              name="bussinesstype"
              value={formData.bussinesstype}
              onChange={handleChange}
              className={`form-control ${errors.bussinesstype ? "is-invalid" : ""}`}
            >
              <option value="">Select Business Type</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Courier">Courier</option>
              <option value="Other">Other</option>
            </select>
            {errors.bussinesstype && <div className="invalid-feedback">{errors.bussinesstype}</div>}
          </div> */}
          {/* <div className="mb-3 w-100">
            <label className="form-label text-light">
              <input
                type="checkbox"
                name="acknowledge"
                checked={formData.acknowledge}
                onChange={handleChange}
              />
              {' '}I agree to the terms and conditions
            </label>
            {errors.acknowledge && <div className="text-danger">{errors.acknowledge}</div>}
          </div> */}
          <button
            type="submit"
            className="btn mt-3 w-100"
            style={{ backgroundColor: "#019ee3", color: "white" }}
          >
            Apply
          </button>
        </form>
      </div>
    </div>
  </div>
</div>


      
    );
};

export default SupportedForm;
