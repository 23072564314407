import React, { useEffect } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import FooterMobileMenu from '../components/FooterMobileMenu';
import MainImage from "../assets/mainImageAddResturant.png"
import Euro from '../assets/EuroImage.png'
import ResForm from '../components/RestaurantForm'
const AddRestaurant = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Add Restaurant Here");
  }, []);
  return (
    <div>

      <>
        {/* Header section start */}
        <Navbar />
        {/* Header Section end */}
        {/* home section start */}
        <section
          id="home"
          className="home-add-rest  home2 section-b-space overflow-hidden"
          style={{
            display: "block !important",
            position: "relative",
            backgroundImage:
              `url(${MainImage})`,
            padding: "50px 0px !important"
          }}
        >
          <div
           className="mobile-background"
            style={{
              background: "#00000061",
              position: "absolute",
              width: "100%",
              height: "100%"
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-7 position-relative">
                <h2 className="mb-3 h2-mobile">
                  Join the Leading Courier Delivery Provider for Takeaway in High
                  Wycombe
                </h2>
                <p className="text-white mb-4">
                  Get More Online Orders with High Wycombe Bites!
                  Discover the smarter way to grow your online sales, reduce commission fees and connect with the local community in High Wycombe

                </p>
                <button
                  className="btn theme-btn mt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#registrationModal"
                >
                  Sign up Today
                </button>
              </div>
              <div className="col-lg-5 col-12"></div>
            </div>
          </div>
        </section>
        {/* home section end */}
        {/* Featured Restaurants section starts */}
        <section className="restaurant-list pt-5 section-b-space ratio3_2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3">
                <img
                  src={Euro}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-lg-7">
                <div className="p-3" >
                  <h1 style={{ fontSize: 24 }}>
                    <strong></strong>Connect with More Customers in High Wycombe
                  </h1>
                  <h3 className='mt-3' style={{ fontWeight: "600px" }}>Attract New Customers</h3>
                  <p className="mt-3">
                    With High Wycombe Bites, your restaurant gets exposure to over 45,000
                    potential local customers, attracting new customers with exclusive offers and discounts{" "}
                  </p>
                  <h3 className='mt-3' style={{ fontWeight: "600px" }}>Turn Visitors into Loyal Customers  </h3>
                  <p className="mt-3">
                    Our platform provides actionable insights,
                    helping you run loyalty programs and keep customers
                    coming back for more.  {" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="restaurant-list pt-5 banner-section section-b-space ratio3_2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="p-3">
                  <h1 style={{ fontSize: 24 }}>
                    We’ll Market Your Restaurant to Thousands{" "}
                  </h1>
                  <h3 className='mt-3' style={{ fontWeight: "600px" }}>Local Customers and Visibility</h3>
                  <p className="mt-3">
                    The High Wycombe Bites platform positions your business in front of more local
                    customers than anyone else, helping you thrive in the community.{" "}
                  </p>
                  <h3 className='mt-3' style={{ fontWeight: "600px" }}>Boost Orders and Grow Sales</h3>
                  <p className="mt-3">
                    Restaurants partnering with us typically
                    see a 28% increase in online orders. Let’s
                    work together to boost your business like never before.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <img
                  src="https://www.digitalutilization.com/static/img/vector-smart.png"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="restaurant-list pt-5 section-b-space ratio3_2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3">
                <img
                  src="https://static.vecteezy.com/system/resources/previews/023/743/925/non_2x/scooter-with-delivery-man-flat-cartoon-character-fast-courier-restaurant-food-service-mail-delivery-service-a-postal-employee-the-determination-of-geolocation-using-electronic-device-free-png.png"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-lg-7">
                <div className="p-3" >
                  <h1 style={{ fontSize: 24 }}>
                    Fastest-Growing Local Food Ordering Platform
                  </h1>
                  <h3 className='mt-3' style={{ fontWeight: "600px" }}>Join the Local Online Food Ordering Platform</h3>
                  <p className="mt-3">
                    High Wycombe Bites is the new go-to platform for takeaways in High Wycombe,
                    connecting local restaurants with hungry customers every day.{" "}
                  </p>
                  <h3 className='mt-3' style={{ fontWeight: "600px" }}>Support for Your Success</h3>
                  <p className="mt-3">
                    We provide tools and insights to help you manage orders efficiently,
                    grow your customer base and maximise your restaurant’s potential{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* featured Restaurants section end */}
        {/* faq section starts */}
        <section className="section-b-space">
          <div className="container">
            <div className="faq-title">
              <h2 className="mb-3">Sign up with us today!</h2>
              <p>Let’s boost your business together</p>
            </div>
            <div className="row g-4">
              <div className="col-xl-4">
                <div className="card" style={{ height: 250 }}>
                  <div className="card-body text-center">
                    <img src="https://server-php-8-3.technorizen.com/amitendra/food-latest/img/restaurant.png" className="mb-2" />
                    <h5>
                      Send Your Restaurant Details
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card" style={{ height: 250 }}>
                  <div className="card-body text-center">
                    <img src="https://server-php-8-3.technorizen.com/amitendra/food-latest/img/id.png" className="mb-2" />
                    <h5>
                      Share Your Documents
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card" style={{ height: 250 }}>
                  <div className="card-body text-center">
                    <img src="https://server-php-8-3.technorizen.com/amitendra/food-latest/img/track.png" className="mb-2" />
                    <h5>
                      Get a Free Printer <br />and Go Live
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 mt-5 text-center">
                <h3 className='mt-3' style={{ fontWeight: "600px" }}>It really is that easy!</h3>
                <p className="mt-3">
                  Join High Wycombe Bites today and see how simple it is to start growing your restaurant's
                  <br />online presence and sales. Let’s make it happen! {" "}
                </p>
                <button
                  className="btn theme-btn mt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#registrationModal"
                >
                  Sign up Today
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* faq section end */}
        <ResForm />
        {/* footer section starts */}
        <Footer />
        {/* footer section end */}
        {/* mobile fix menu start */}
        <FooterMobileMenu />
        {/* mobile fix menu end */}
        {/* location offcanvas start */}
        <div
          className="modal fade location-modal"
          id="location"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title">
                  <h5 className="fw-semibold">Select a Location</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
              </div>
              <div className="modal-body">
                <div className="search-section">
                  <form className="form_search" role="form">
                    <input
                      type="search"
                      placeholder="Search Location"
                      className="nav-search nav-search-field"
                    />
                  </form>
                </div>
                <a href="#!" className="current-location">
                  <div className="current-address">
                    <i className="ri-focus-3-line focus" />
                    <div>
                      <h5>Use current-location</h5>
                      <h6>Wellington St., Ottawa, Ontario, Canada</h6>
                    </div>
                  </div>
                  <i className="ri-arrow-right-s-line arrow" />
                </a>
                <h5 className="mt-sm-3 mt-2 fw-medium recent-title dark-text">
                  Recent Location
                </h5>
                <a href="#!" className="recent-location">
                  <div className="recant-address">
                    <i className="ri-map-pin-line theme-color" />
                    <div>
                      <h5>Bayshore</h5>
                      <h6>kingston St., Ottawa, Ontario, Canada</h6>
                    </div>
                  </div>
                </a>
              </div>
              <div className="modal-footer">
                <a href="#" className="btn gray-btn" data-bs-dismiss="modal">
                  Close
                </a>
                <a href="#" className="btn theme-btn mt-0" data-bs-dismiss="modal">
                  Save
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* location offcanvas end */}
        {/* tap to top start */}
        <button className="scroll scroll-to-top">
          <i className="ri-arrow-up-s-line arrow" />
        </button>
        {/* tap to top end */}
        {/* responsive space */}
        {/* <div className="responsive-space" /> */}
        {/* responsive space */}
        {/* bootstrap js */}
        {/* footer accordion js */}
        {/* loader js */}
        {/* swiper js */}
        {/* script js */}
      </>



    </div>
  )
}

export default AddRestaurant