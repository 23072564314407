import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
// Inline styles for the banner and button
const bannerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    textAlign: 'center',
    padding: '10px',
    zIndex: 1000,
    float: 'left', // Ensures the banner floats over content
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' // Adding a shadow for floating effect
  };
  
  const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#fff',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '0 5px',
    float: 'center' // Ensures the button floats to the right of the banner
  };
  useEffect(() => {
    // Check localStorage to see if the user has already accepted cookies
    const acceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!acceptedCookies) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    // Set localStorage to remember the user's choice
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null; // Don't render anything if cookies have been accepted
  }

  return (
    <div style={bannerStyle}>
      <p style={{ margin: 0, fontSize:"small" }}>
        We use cookies to track usage and preferences.{' '}
        <button onClick={handleAccept} style={buttonStyle}>
          I Understand
        </button>
      </p>
    </div>
  );
};



export default CookieConsent;
