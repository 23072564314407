import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { getcart } from "../store/feature/cartSlice";
import { addToCart, addOrder, loyalty } from "../utils/api";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import FooterMobileMenu from '../components/FooterMobileMenu';
import Delivery from '../components/delivery';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setActiveTab } from '../store/feature/userSlice';
import moment from 'moment';

const Cart = () => {




  const storedUser = JSON.parse(localStorage.getItem('user'));
  const Address = JSON.parse(localStorage.getItem('userAddress'));
  const { cartItems } = useSelector((store) => store.Cart);

  const [cartItem, setCartItem] = useState(null);
  const [CartFoods, setCartFoods] = useState([]);
  const [quantities, setQuantities] = useState([]); // State to store quantities
  const [show, setShow] = useState(false);
  const [buttonText, setButtonText] = useState('PAY ONLINE');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartId, setCartId] = useState("");
  const [restId, setRestId] = useState("");
  const [type, setType] = useState("");
  // const [catIds, setCatIds] = useState([]);
  // const [foodIds, setFoodIds] = useState([]);
  // const [typeIds, setTypeIds] = useState([]);
  const [extraTopupIds, setExtraTopupIds] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [foodQntity, setFoodQntity] = useState(0)
  const [couponCode, setCouponCode] = useState("");
  const [pincode, setPincode] = useState("")
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isCouponValid, setIsCouponValid] = useState(true);
  const [inputCouponCode, setInputCouponCode] = useState('');
  const activeTab = useSelector((store) => store.User.activeTab);


  useEffect(() => {
    const user = localStorage.getItem('user'); // Check if the user is logged in
    if (!user) {
      navigate('/login'); // Redirect to login if user is not logged in
    }
  }, [navigate]);
  // coupon 
  const handleCouponInputChange = (event) => {
    setInputCouponCode(event.target.value);
  };


  const validateCoupon = () => {
    if (!inputCouponCode) {
      setIsCouponValid(false);
      return "Please enter a coupon code.";
    }

    const coupon = cartItems.couponlist.find(c => c.couponname === inputCouponCode);

    if (!coupon) {
      setIsCouponValid(false);
      return "Invalid coupon code.";
    }

    const currentDate = new Date();
    const expiryDate = new Date(coupon.expirydate);
    if (expiryDate < currentDate) {
      setIsCouponValid(false);
      return "Coupon has expired.";
    }

    if (parseFloat(coupon.minvalue) > totalFoodPrice) {
      setIsCouponValid(false);
      return `Minimum order value of ${coupon.minvalue} is required for this coupon.`;
    }

    // if (coupon.isactive === "0") {
    //   setIsCouponValid(false);
    //   return "Coupon is not active.";
    // }

    setIsCouponValid(true);
    return null;
  };


  const applyCoupon = () => {
    const validationMessage = validateCoupon();
    if (validationMessage) {
      setSelectedCoupon(null);
      toast.error(validationMessage); // Show validation message
    } else {
      const coupon = cartItems.couponlist.find(c => c.couponname === inputCouponCode);
      setSelectedCoupon(coupon);
    }
  };

  // end

  useEffect(() => {
    dispatch(getcart());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (cartItems && cartItems.cartmasters && cartItems.cartmasters.length > 0) {
      const cartmaster = cartItems.cartmasters[0];
      setFoodQntity(cartmaster?.foods.length);
      setQuantities(cartmaster.foods.map(food => food.quantity || 1));
      const extraTopupIds = cartmaster.foods.flatMap(food =>
        food.carttopupdetails.flatMap(topup =>
          topup.cartextratopupdetails.map(detail => detail.extratopupid)
        )
      );

      setCartFoods(cartmaster.foods);
      setCartItem(cartmaster);
      setQuantities(cartmaster.foods.map(food => food.quantity || 1));
      // Set values for the additional fields
      setCartId(cartmaster.cartid);
      setRestId(cartmaster.restid);
      setType(cartmaster.type);

      setExtraTopupIds(extraTopupIds);
    }

    if (cartItems && cartItems.deliveryfeesdata && cartItems.deliveryfeesdata.length > 0) {
      const fees = cartItems.deliveryfeesdata.map(delivery => delivery.pincode);
      setPincode(fees);
    }
  }, [cartItems]);

  // const calculateCouponDiscount = () => {
  //   let discount = 0;

  //   if (selectedCoupon) {
  //     const couponValue = parseFloat(selectedCoupon.value) || 0;
  //     const minOrderValue = parseFloat(selectedCoupon.minvalue) || 0;

  //     if (totalFoodPrice >= minOrderValue) {
  //       if (selectedCoupon.type === "Percentage") {
  //         discount = (totalFoodPrice * couponValue) / 100;
  //       }
  //       //  else if (selectedCoupon.type === "Amount") {
  //       //   discount = couponValue;
  //       // }
  //     }
  //   }

  //   return discount;
  // };

  const calculateCouponDiscount = () => {
    let discount = 0;

    if (selectedCoupon) {
      const couponValue = parseFloat(selectedCoupon.value) || 0;
      const minOrderValue = parseFloat(selectedCoupon.minvalue) || 0;

      if (totalFoodPrice >= minOrderValue) {
        if (selectedCoupon.type === "Percentage") {
          discount = (totalFoodPrice * couponValue) / 100;
        } else if (selectedCoupon.type === "Amount") {
          discount = couponValue;
        }
      }
    }

    return discount;
  };

  const totalFoodPrice = CartFoods.reduce((total, food, index) => {
    const typeCost = parseFloat(food.typecost) || 0;
    const extraTopUpCost = food.carttopupdetails.reduce((acc, topup) => {
      return acc + parseFloat(topup.cartextratopupdetails.reduce((acc2, extra) => {
        return acc2 + parseFloat(extra.extratopupcost) || 0;
      }, 0)) || 0;
    }, 0);
    return total + (typeCost + extraTopUpCost) * quantities[index];
  }, 0);

  const serviceCharge = parseFloat(cartItem?.servicecharge) || 0;

  const deliveryCharge = parseFloat(cartItem?.deliverycharge) || 0;
  const couponDiscount = calculateCouponDiscount();

  const totalAmountToPay = activeTab === "Delivery"
    ? totalFoodPrice + serviceCharge + deliveryCharge - couponDiscount
    : totalFoodPrice + serviceCharge - couponDiscount;

  const minOrder = cartItem ? parseFloat(cartItem.minimumorder) || 0 : 0;
  // Disable checkout button if total amount is less than minimum order value
  const isCheckoutDisabled = totalAmountToPay < minOrder;
  const amountToAdd = isCheckoutDisabled ? minOrder - totalAmountToPay : 0;
  const minOrderValue = parseFloat(selectedCoupon?.minvalue) || 0;
  const isCouponEnabled = totalAmountToPay >= minOrderValue;


  // console.log("Total Food Price:", totalFoodPrice);
  // console.log("Service Charge:", serviceCharge);
  // console.log("Delivery Charge:", deliveryCharge);
  // console.log("Coupon Discount:", couponDiscount);
  // console.log("Total Amount to Pay:", totalAmountToPay);


  // increament or decreament 


  const handleProceed = async (food, quantity) => {
    console.log(food, "cartfood")
    if (!food || quantity === undefined || quantity === null) {
      // console.error("Invalid food or quantity", { food, quantity });
      return;
    }

    const sendData = {
      userid: storedUser?.userid,
      restId: restId,
      type: activeTab == "Delivery" ? "delivery" : "takeaway",
      catid: food?.catid || "",
      foodid: food?.foodid || "",
      typeid: food?.typeid || "",
      extratopupid: extraTopupIds.length > 0 ? extraTopupIds : [], // Ensure this is a string
      quantity: quantity.toString(),
      cartid: cartId,
      cartdetailid: food.cartdetailid,
    };

    // console.log(sendData, "datataaaaa");
    const data = await addToCart(sendData);
    // dispatch(getcart());
    console.log(data, "data")
  };

  const handleQuantityChange = (index, newQuantity) => {
    const newQuantities = [...quantities];
    newQuantities[index] = newQuantity;
    setQuantities(newQuantities);

    // Ensure food is valid before proceeding
    const food = CartFoods[index];
    if (food) {
      handleProceed(food, newQuantity);
    } else {
      console.error("Food is undefined at index:", index);
    }
  };

  // const handleIncrement = (index) => {
  //   if (quantities[index]) {
  //     handleQuantityChange(index, quantities[index] + 1);
  //   }
  // };

  // const handleDecrement = (index) => {
  //   if (quantities[index]) {
  //     handleQuantityChange(index, quantities[index] - 1);
  //   }else{
  //     dispatch(getcart());  
  //     console.log("get cart api ")
  //   }
  // };


  // end


  const handleIncrement = (index) => {
    setQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      // Parse the current quantity to ensure it's a number
      const currentQuantity = parseInt(newQuantities[index], 10) || 0;
      newQuantities[index] = currentQuantity + 1; // Increment quantity
      handleProceed(CartFoods[index], newQuantities[index]); // Call to handleProceed
      return newQuantities;
    });
  };


  const handleDecrement = (index) => {
    setQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      const currentQuantity = parseInt(newQuantities[index], 10) || 0;
      if (currentQuantity > 0) {
        newQuantities[index] = currentQuantity - 1; // Decrement quantity
        handleProceed(CartFoods[index], newQuantities[index]); // Call to handleProceed
      } else {
        console.log("Cannot decrement further.");
      }
      return newQuantities;
    });
  };

  const handleNavigate = async () => {
    const savedTab = localStorage.getItem('user');
    if (savedTab) {
      navigate('/payment');
    } else {
      navigate('/login');
    }
  };

  // const handleCashOnDeliveryClick = () => {
  //   setButtonText('CONFIRM ORDER');
  // };

  // const handleConfirmOrderClick = () => {
  //   if (buttonText === 'CONFIRM ORDER') {
  //     Addorder()
  //     navigate('/confirmorder');
  //   }
  // };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // add order api 


  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  // this is for loyalty 
  const [totalPoints, setTotalPoints] = useState(0);

  const points = async () => {
    const data = {
      userid: storedUser?.userid,
    };

    try {
      const response = await loyalty(data);
      if (response.status === "1") {
        const total = response.details.reduce((sum, item) => sum + parseFloat(item.loyaltipoint), 0);
        setTotalPoints(total);
      } else {
        console.error("Error fetching loyalty points:", response.returnmsg);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    points();
  }, []);

  // end loyalty
  const [instruction, setInstruction] = useState("");

  const handleInstructionChange = (e) => {
    setInstruction(e.target.value);
  };

  const [paymentMode, setPaymentMode] = useState('Stripe'); // Default payment modeconst 
  // handlePaymentModeChange = (mode) => {
  //   setPaymentMode(mode);
  // };


  const Addorder = async () => {
    const paymentDateTime = getCurrentDateTime();

    if (!isCouponValid) {
      toast.error("Total amount is less than the minimum order value required for the selected coupon.");
      return;
    }

    const data = {

      customerid: storedUser?.userid,
      orderreferenceid: "",
      customerreqtime: selectedTime,
      ordertype: activeTab,
      deliverypostcode: pincode,
      restaurantid: restId,
      deliveryaddress: Address?.address,
      couponcodeid: couponId,
      instructionofcooking: instruction,
      loyaltipointsconsume: "0.0",
      totalitem: foodQntity.toString(),
      coupondiscount: couponDiscount.toString(),
      loyaltidiscount: "0.0",
      deliverycharges: cartItem?.deliverycharge,
      servicecharges: cartItem?.servicecharge,
      netpayamount: totalAmountToPay.toString(),
      paymentmode: paymentMode,
      paymentreferenceid: "",
      paymentdatetime: paymentDateTime,
      orderdate: paymentDateTime
    };

    try {
      const order = await addOrder(data);
      if (order.status =="0") {
         navigate("/cancel")
        }
        console.log(order.status, "order2666666666");

      if (order.status === "1") {
        if (paymentMode === "Stripe") {
          window.location.href = order.paymenturl;
        } else {
          toast.success("Order placed successfully!");
          navigate('/confirmorder');
        }
      } else {
        toast.error("Failed to place the order. Please try again.");
      }

      
    } catch (error) {
      toast.error("An error occurred while placing the order.");
    }
  };

  const handlePaymentModeChange = (mode) => {
    console.log(mode, "mode")
    setPaymentMode(mode);
  };
  const handleConfirmOrderClick = () => {
    if (buttonText === 'CONFIRM ORDER') {
      Addorder()
      navigate('/confirmorder');
    }
  };

  const handleCashOnDeliveryClick = async() => {
    handlePaymentModeChange('Cash On Delivery');
    setButtonText('CONFIRM ORDER');
    if (buttonText === 'CONFIRM ORDER') {
      const res = await Addorder()
      console.log(res,"res")
      // navigate('/confirmorder');
    }
    // Addorder()
  };

  const handlePayOnlineClick = () => {
    handlePaymentModeChange('Stripe');
    Addorder();
  };


  const handleCouponChange = (e) => {
    const couponId = e.target.value;
    const selectedCoupon = cartItems.couponlist.find(coupon => coupon.couponid === couponId);
    setSelectedCoupon(selectedCoupon);
  };



  const openTime = activeTab == "Delivery" ? cartItem?.showdeliveryopentime : cartItem?.showtakeawayopentime;
  const closeTime = activeTab == "Delivery" ? cartItem?.deliveryclosedtime : cartItem?.takeawayclosedtime;

  // Time options (you can customize these)
  const generateTimeSlots = (openTime, closeTime) => {
    const timeSlots = [];

    // Parse the input times into moment objects
    let startTime = moment(openTime, 'hh:mm A'); // e.g., "12:00 PM"
    const endTime = moment(closeTime, 'hh:mm A'); // e.g., "10:00 PM"

    // Generate 30-minute interval time slots
    while (startTime.isBefore(endTime)) {
      timeSlots.push(startTime.format('h:mm A')); // Push formatted time string
      startTime = startTime.add(30, 'minutes');  // Increment by 30 minutes
    }

    return timeSlots;
  };

  const [selectedTime, setSelectedTime] = useState("As soon as possible");
  const [timeOptions, setTimeOptions] = useState(["As soon as possible"]);

  // Handle time selection
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  // Update time options based on openTime and closeTime
  useEffect(() => {
    if (openTime && closeTime && closeTime.toLowerCase() !== 'closed') {
      const slots = generateTimeSlots(openTime, closeTime);
      setTimeOptions(["As soon as possible", ...slots]); // Add "As soon as possible" as the first option
    }
  }, [openTime, closeTime]);

  // Handle time selection
  // const handleTimeChange = (event) => {
  //   setSelectedTime(event.target.value);
  // };

  return (
    <>
      <Navbar />
      <section className="section-t-space mytabb overflow-hidden pt-120">
        <Delivery />
      </section>
      <section className="page-head-section">
        <div className="container page-heading">
          <h2 className="h3 mb-3 text-white text-center">Cart</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="ri-home-line" />
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Cart
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="account-section section-b-space pt-0">
        <div className="container">
          <div className="layout-sec">
            <div className="row g-lg-4">
              <div className="col-lg-12">
                <div className="order-summery-section sticky-top">
                  <div className="checkout-detail">

                    <ul>
                      {CartFoods.map((food, index) => {
                        const typeCost = parseFloat(food.typecost) || 0;
                        const extraTopUpCost = food.carttopupdetails.reduce((total, topup) => {
                          return total + parseFloat(topup.cartextratopupdetails.reduce((acc, extra) => {
                            return acc + parseFloat(extra.extratopupcost) || 0;
                          }, 0)) || 0;
                        }, 0);
                        const totalPrice = (typeCost + extraTopUpCost) * quantities[index];

                        return (
                          <li key={index}>
                            <div className="horizontal-product-box">
                              <div className="product-content">
                                <div className="d-flex align-items-center justify-content-between">
                                  <h5>{food.food}</h5>
                                  <h6 className="product-price">£{totalPrice.toFixed(2)}</h6>
                                </div>
                                <h6 className="ingredients-text">{food.menutype}</h6>
                                <div className="d-flex align-items-center justify-content-between mt-md-2 mt-1 gap-1">
                                  {/* <h6 className="place">Serve 1</h6> */}
                                  <div className="plus-minus">
                                    <i
                                      className="ri-subtract-line sub"
                                      onClick={() => handleDecrement(index)}
                                    />
                                    <input
                                      type="number"
                                      value={quantities[index]}

                                      readOnly
                                    />
                                    <i
                                      className="ri-add-line add"
                                      onClick={() => handleIncrement(index)}
                                    />
                                  </div>
                                </div>





                              </div>
                            </div>
                          </li>
                        );
                      })}

                      {
                        activeTab === "Delivery" && cartItem?.isdeliveryavailable !== "1" && (
                          <h6 style={{ textAlign: "center", color: "#ff0000bd" }} className='availabe-check'>
                            We are not delivering to the postcode that was supplied
                          </h6>
                        )
                      }
                      <li>
                        {activeTab === 'Delivery' && (
                          <>
                            <div className="coupon-section p-3 border rounded">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  <i className="ri-home-4-fill icon" style={{ color: "#009FE3" }} /> Delivery Address
                                </div>
                                <div className="address-box white-bg new-address-box white-bg">
                                  <Link
                                    className="btn new-address-btn theme-outline rounded-2 mt-0"
                                    data-bs-toggle="modal"
                                    data-bs-target="#location"
                                  >
                                    Change
                                  </Link>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mt-md-2 mt-1 gap-1">
                                <h6>{Address?.address}</h6>
                              </div>
                            </div>

                            <div className="coupon-section p-3 border rounded mt-1">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Delivery Time</div>
                                <div className="address-box white-bg new-address-box white-bg">
                                  <select
                                    value={selectedTime}
                                    onChange={handleTimeChange}
                                    className="form-select"
                                    aria-label="Select delivery time"
                                  >
                                    {timeOptions.map((option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {activeTab === 'Collection' && (
                          <div className="coupon-section p-3 border rounded mt-1">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Collection Time</div>
                              <div className="address-box white-bg new-address-box white-bg">
                                <select
                                  value={selectedTime}
                                  onChange={handleTimeChange}
                                  className="form-select"
                                  aria-label="Select collection time"
                                >
                                  {timeOptions.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                      <li style={{ borderTopStyle: "none" }}>
                        <div className="coupon-section p-3 border rounded">
                          <h6 className="mb-3">Apply Coupon</h6>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              value={inputCouponCode}
                              onChange={handleCouponInputChange}
                              placeholder="Enter coupon code"
                            />
                            <button
                              className="btn"
                              onClick={applyCoupon}
                              style={{ backgroundColor: "#009FE3", color: "white" }}
                            >
                              Apply
                            </button>
                          </div>
                          {!isCouponValid && (
                            <div className="alert alert-danger" role="alert">
                              Invalid coupon code or conditions not met.
                            </div>
                          )}
                          {selectedCoupon && (
                            <div className="alert alert-success" role="alert">
                              Coupon applied: {selectedCoupon.couponname} - {selectedCoupon.value}{selectedCoupon.type === "Percentage" ? "%" : "£"} off
                            </div>
                          )}

                          <h6 className="mb-3">Special Instructions</h6>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              value={instruction}
                              onChange={handleInstructionChange}
                              placeholder="Any specific cooking instructions?"
                            />
                          </div>
                        </div>


                      </li>

                      {/* <li>
                                                 <div className="instruction-field">
                    <h5>Special Instructions</h5>
                    <input
                      type="text"
                      value={instruction}
                      onChange={handleInstructionChange}
                      placeholder="Any specific cooking instructions?"
                      className="form-control"
                    />
                  </div>
                      </li> */}
                    </ul>



                    <h5 className="bill-details-title fw-semibold dark-text">
                      Bill Details
                    </h5>
                    <div className="sub-total">
                      <h6 className="content-color fw-normal">Sub Total</h6>
                      <h6 className="fw-semibold">£{totalFoodPrice.toFixed(2)}</h6>
                    </div>
                    {
                      activeTab == "Delivery" &&

                      <div className="sub-total">
                        <h6 className="content-color fw-normal">Delivery Charge</h6>
                        <h6 className="fw-semibold">£{deliveryCharge.toFixed(2)}</h6>
                      </div>
                    }
                    {/* <div className="sub-total">
                      <h6 className="content-color fw-normal">Loyalty Point</h6>
                      <h6 className="fw-semibold">${totalPoints.toFixed(2)}</h6>
                    </div> */}
                    <div className="sub-total">
                      <h6 className="content-color fw-normal">Service Charge</h6>
                      <h6 className="fw-semibold">£{serviceCharge.toFixed(2)}</h6>
                    </div>
                    {selectedCoupon && (
                      <>
                        <div className="sub-total">
                          <h6 className="content-color fw-normal">Coupon Discount</h6>
                          <h6 className="fw-semibold">- £{couponDiscount.toFixed(2)}</h6>
                        </div>
                      </>

                    )}
                    <div className="grand-total">
                      <h6 className="fw-semibold dark-text">To Pay</h6>
                      <h6 className="fw-semibold amount">£{totalAmountToPay.toFixed(2)}</h6>
                    </div>

                    <div className="container">
                      <Button className='btn theme-btn restaurant-btn w-100 rounded-2' onClick={handleShow}
                        disabled={(activeTab === "Delivery" && cartItem?.isdeliveryavailable !== "1") || isCheckoutDisabled}       >
                        CHECKOUT
                      </Button>
                      {isCheckoutDisabled && (
                        <div style={{ color: 'red', marginTop: '10px' }} className='availabe-check'>
                          Minimum order value is £{minOrder.toFixed(2)}. Please add £{amountToAdd.toFixed(2)} more to proceed.
                        </div>
                      )}
                      <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>Select Payment Method</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="d-flex justify-content-center w-100">
                            {/* Cash On Delivery Button */}
                            <Button
                              onClick={handleCashOnDeliveryClick}
                              className={`me-2 btn theme-btn ${buttonText === 'CONFIRM ORDER' ? 'active' : ''}`}
                            >
                              {buttonText === 'CONFIRM ORDER' ? 'CONFIRM ORDER' : 'CASH ON DELIVERY'}
                            </Button>

                            {/* Pay Online Button */}
                            {/* <Button
            className={`btn theme-btn ${paymentMode === 'Stripe' ? 'active' : ''}`}
            onClick={handlePayOnlineClick}
            disabled={buttonText === 'CONFIRM ORDER'} // Disable Pay Online button if Confirm Order button is active
          >
            PAY ONLINE
          </Button> */}

                            {buttonText !== 'CONFIRM ORDER' && (
                              <Button
                                className="btn theme-btn"
                                onClick={handlePayOnlineClick}
                              >
                                PAY ONLINE
                              </Button>
                            )}
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <FooterMobileMenu />
    </>
  );



};


export default Cart;


